import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useHistory, generatePath } from 'react-router-dom';

// styled
import Typography from '../../components/commons/Typography';
import * as R from 'ramda';

// components
import MainLayout from '../../components/layouts/MainLayout/MainLayout';
import AsideLeftCampaign from '../../components/campaigns/AsideLeftCampaign';
import CreateCampaignDetail from '../../components/campaigns/CreateCampaign/CreateCampaignDetail/CreateCampaignDetail';
import KolsRequirement from '../../components/campaigns/CreateCampaign/KolRequirement/KolsRequirement';
import CampaignPreview from '../../components/campaigns/CreateCampaign/CampaignPreview/CampaignPreview';
import ModalCampaignCancel from '../../components/modals/ModalCampaignCancel';
// query

import CREATE_CAMPAIGN from '../../queries/campaignCreation/createCampaign';
import PUBLISH_CAMPAIGN from '../../queries/campaignCreation/publishCampaign';
import UPDATE_CAMPAIGN_DRAFT from '../../queries/campaignCreation/updateDraftCampaign';
import INSERT_MULTIPLE_IMAGES from '../../queries/campaignCreation/addNewImage';
import DELETE_CAMPAIGN from '../../queries/campaignCreation/deleteCampaign';

import useCommonApi from '../../hooks/useCommon';
import useGuard from '../../hooks/useGuard';
import { PRIVATE_ROUTE, ROUTES } from '../../utils/constants';
import qs from 'query-string';
import useCampaign from '../../hooks/useCampaign';
import { uploadFileHandler } from '../../utils/heplers';
import useMobile from '../../hooks/useMobile';
import useToastError from '../../hooks/useToastError';
import delay from '../../utils/delay';
import ClientProfileContext from '../../containers/ClientProfileProvider';

const ALLOW_STEP = [0, 1, 2];
const CREATE_STEPS = {
  campaignDetail: ALLOW_STEP[0],
  kolRequirement: ALLOW_STEP[1],
  campaignReview: ALLOW_STEP[2],
};

function CreateCampaign() {
  const { draftCampaignId, onSetDraftCampaignId, onGetCampaignById, campaignDetail } = useCampaign();
  const { listCategory, onRefreshListCategory } = useCommonApi();
  const { user: currentUser, onRefreshProfile, onRefreshUser, onRefreshWallet } = useGuard();
  const history = useHistory();
  const isMobile = useMobile();
  const btnSubmitRef = useRef<any>();
  const btnSubmitDetailRef = useRef<any>();
  const [step, setStep] = useState(CREATE_STEPS.kolRequirement);
  const [formData, setFormData] = useState<any>({});
  const [isSuccessPublish, setIsSucessPublish] = useState(false);
  const [isFailedPublish, setIsFailedPublish] = useState(false);
  const [isShowModel, setIsShowModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDraft, setSaveDraft] = useState(false);
  const [createCampaign] = useMutation(CREATE_CAMPAIGN);
  const [publishCampaign] = useMutation(PUBLISH_CAMPAIGN);
  const [updateCampaignDraft] = useMutation(UPDATE_CAMPAIGN_DRAFT);
  const [addMultipleImages] = useMutation(INSERT_MULTIPLE_IMAGES);
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);
  const { toastError } = useToastError();
  const { currentProfile } = useContext(ClientProfileContext);

  const query = qs.parse(history.location.search) as any;

  const handleBackToStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
    if (step === 0) {
      setIsShowModel(true);
    }
  };

  const submitCampaign = (formValue) => {
    setFormData({ ...formData, ...formValue });
    setStep(CREATE_STEPS.kolRequirement);
  };

  const submitRequirement = async (formValue) => {
    setIsLoading(true);
    const formatFormValue = formValue.map((data) => {
      delete data.id;
      data.mapping_tranche_languages?.data?.map((lang) => {
        delete lang.value;
        delete lang.label;
        return lang;
      });
      return data;
    });

    const dataRequest = {
      ...formData,
      tranches: {
        data: formatFormValue,
      },
    };

    setFormData(dataRequest);

    if (draftCampaignId && campaignDetail?.status === 'DRAFT') {
      handleUpdateCampaignDraft(formValue);
      return;
    }

    try {
      let arrayAvatar: any = [];
      if (formData.avatar && formData.avatar.length > 0) {
        const newList = formData.avatar.map((image) => {
          return uploadFileHandler(image);
        });
        arrayAvatar = await Promise.all(newList);
      }

      const result = await createCampaign({
        variables: {
          ...dataRequest,
          image_url: arrayAvatar[0].url,
          client_profile_id: currentProfile?.id,
        },
      });

      setIsLoading(false);
      if (result && result.data) {
        const listAvatar = arrayAvatar.map((element) => {
          return addMultipleImages({
            variables: {
              campaignId: result.data.createCampaign.campaign.id,
              fileId: element.id,
            },
          });
        });
        await Promise.all(listAvatar);
        const path = generatePath(ROUTES.campaignsCreate);
        history.push(path + `?draftId=${result.data?.createCampaign?.campaign?.id}`);
        if (isSaveDraft) {
          history.push(PRIVATE_ROUTE.campaigns);
          return;
        }
        onGetCampaignById(result.data?.createCampaign?.campaign?.id);
        setStep(CREATE_STEPS.campaignReview);
      }
    } catch (error) {
      setIsLoading(false);
      toastError('Something wrongs, please try again');
    }
  };

  const handlePublishCampaign = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      if (campaignDetail?.id) {
        const result = await publishCampaign({
          variables: {
            campaignId: campaignDetail.id,
          },
        });
        setIsLoading(false);
        if (result && result.data) {
          setIsSucessPublish(true);
          await delay(2000);
          onRefreshWallet();
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsFailedPublish(true);
      toastError('Something wrongs, please try again');
    }
  };

  const handleUpdateCampaignDraft = async (listTranceNew) => {
    try {
      let uploadAvatar;
      if (formData.avatar && formData.avatar !== campaignDetail?.image_url && typeof formData.avatar === 'object') {
        const newList = formData.avatar.filter((image) => image instanceof File);

        const listUpload = newList.map((image) => {
          return uploadFileHandler(image);
        });

        uploadAvatar = await Promise.all(listUpload);

        const listAvatar = uploadAvatar.map((element) => {
          return addMultipleImages({
            variables: {
              campaignId: formData.id,
              fileId: element.id,
            },
          });
        });

        await Promise.all(listAvatar);
      }

      let listTranceOrigin = (formData?.tranches?.data || formData?.tranches || []).map(removeContentTypeFromDeliverableSpec);

      const listTranceReplace = [] as any[];
      const listIdRemove = [] as any[];
      const listTranceAdd = listTranceNew.filter((t) => !t.id).map(removeContentTypeFromDeliverableSpec);
      listTranceOrigin.forEach((trance) => {
        const findTranceReplace = listTranceNew.find((t) => trance.id === t.id) as any;
        if (findTranceReplace) {
          listTranceReplace.push(findTranceReplace);
        } else {
          listIdRemove.push(trance.id);
        }
      });

      const result = await updateCampaignDraft({
        variables: {
          id: draftCampaignId,
          campaign: {
            name: formData?.name || campaignDetail?.name,
            product_name: formData?.product_name || campaignDetail?.product_name,
            brand_name: formData?.brand_name || campaignDetail?.brand_name,
            description: formData?.description || campaignDetail?.description,
            recruitment_end_date: formData?.recruitment_end_date || campaignDetail?.recruitment_end_date,
            start_date: formData?.start_date || campaignDetail?.start_date,
            end_date: formData?.end_date || campaignDetail?.end_date,
            image_url: uploadAvatar?.url || campaignDetail?.image_url,
          },
          replace_tranches: mappingListTrance(listTranceReplace as any),
          remove_tranches: [...listIdRemove],
          add_tranches: mappingListTrance(listTranceAdd),
        },
      });
      setIsLoading(false);
      if (result?.data && result.data?.updateCampaign?.id) {
        if (isSaveDraft) {
          history.push(PRIVATE_ROUTE.campaigns);
          return;
        }
        setStep(CREATE_STEPS.campaignReview);
      }
    } catch (error) {
      setIsLoading(false);
      toastError('Something wrongs, please try again');
    }
  };

  const mappingCategoryCodeToString = ({ data = [] }) => {
    return data.map((d: any) => d?.category_code);
  };

  const mappingDeliverableSpecs = (listDeliverableSpecs = []) => {
    if (!Array.isArray(listDeliverableSpecs)) return [];
    return listDeliverableSpecs.map((ds: any) => {
      delete ds.id;
      delete ds.__typename;
      return ds;
    });
  };

  // When call API updateCampaign, must Remove content_type from deliverable_specs
  // The db doesn't accept it
  function removeContentTypeFromDeliverableSpec(tranche) {
    if (tranche.deliverable_specs.data && Array.isArray(tranche.deliverable_specs.data)) {
      try {
        const deleverables = tranche.deliverable_specs.data.map((deliverable) => {
          const { content_type, ...rest } = deliverable;
          return rest;
        });
        const updatedTranche = { ...tranche, deliverable_specs: { data: deleverables } };
        return updatedTranche;
      } catch (error) {
        console.log('removeContentTypeFromDeliverableSpec_error', error);
        return tranche;
      }
    }

    if (tranche.deliverable_specs && Array.isArray(tranche.deliverable_specs)) {
      try {
        const deleverables = tranche.deliverable_specs.map((deliverable) => {
          const { content_type, ...rest } = deliverable;
          return rest;
        });
        const updatedTranche = { ...tranche, deliverable_specs: deleverables };
        return updatedTranche;
      } catch (error) {
        console.log('removeContentTypeFromDeliverableSpec_error', error);
        return tranche;
      }
    }
    return tranche;
  }

  const mappingListTrance = (listTrance = []) => {
    return listTrance.map((trance: any) => {
      const newTrance = {
        ...trance,
        age_groups_codes: mappingCategoryCodeToString(trance?.age_groups),
        gender_codes: mappingCategoryCodeToString(trance?.genders),
        product_category_codes: mappingCategoryCodeToString(trance?.product_categories),
        social_platform_codes: mappingCategoryCodeToString(trance?.social_platforms),
        deliverable_specs: mappingDeliverableSpecs(trance?.deliverable_specs?.data),
      };
      if (!newTrance.id) delete newTrance.id;
      delete newTrance.age_groups;
      delete newTrance.genders;
      delete newTrance.product_categories;
      delete newTrance.social_platforms;
      return newTrance;
    });
  };

  const handleCancelCreateCampaign = () => {
    setIsShowModel(true);
  };

  const handleCancelKols = (form) => {
    setIsShowModel(true);

    const formatFormValue = form.map((data) => {
      if (data.id == null) {
        delete data.id;
      }
      return data;
    });
    const dataRequest = {
      ...formData,
      tranches: {
        data: formatFormValue,
      },
    };
    setFormData(dataRequest);
  };

  useEffect(() => {
    if (Object.keys(listCategory).length === 0) {
      onRefreshListCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentUser.profile || !currentUser.profile.wallet_id) {
      onRefreshUser();
      onRefreshProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (campaignDetail && campaignDetail?.status === 'DRAFT') {
      setFormData(campaignDetail);
    }
    handleUpdateCampaign(campaignDetail);
  }, [campaignDetail]);

  useEffect(() => {
    if (R.is(String, query.step) && R.is(String, query.draftId)) {
      setStep(stepValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (draftCampaignId !== query?.draftId) {
      onSetDraftCampaignId(query.draftId);
      onGetCampaignById(query.draftId);
      return;
    }
    if (!query?.draftId) {
      setFormData({});
      setStep(CREATE_STEPS.campaignDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.draftId, draftCampaignId]);

  useEffect(() => {
    if (query.step !== step) {
      const queryParams = qs.parse(history.location.search) as any;
      const newQueries = { ...queryParams, step };
      history.push({ search: qs.stringify(newQueries) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.step, step]);

  const handleUpdateCampaign = (campaignDetail) => {
    if (campaignDetail && campaignDetail?.status === 'DRAFT') {
      setFormData(campaignDetail);
    }
  };

  const handleEditCampaign = (campaignId) => {
    setStep(0);
    onSetDraftCampaignId(campaignId);
    onGetCampaignById(campaignId);
  };
  const stepValid = useMemo(() => {
    const parseStepToNumber = +query.step;
    if (!Number.isInteger(parseStepToNumber)) return CREATE_STEPS.campaignDetail;
    if (ALLOW_STEP.includes(parseStepToNumber)) return parseStepToNumber;
    return CREATE_STEPS.campaignDetail;
  }, [query.step]);

  const backToStep = useCallback((value) => {
    setStep(value);
  }, []);

  const renderAsideRight = () => {
    switch (step) {
      case CREATE_STEPS.campaignDetail:
      case CREATE_STEPS.kolRequirement:
        return (
          <>
            <div style={{ display: step === CREATE_STEPS.campaignDetail ? 'block' : 'none' }}>
              <CreateCampaignDetail
                submitCampaign={submitCampaign}
                formData={formData}
                onCancelCreateCampaign={handleCancelCreateCampaign}
                btnSubmitDetailRef={btnSubmitDetailRef}
                handleSaveDraft={setSaveDraft}
                step={step}
              />
            </div>
            <div style={{ display: step === CREATE_STEPS.kolRequirement ? 'block' : 'none' }}>
              <KolsRequirement
                profileData={currentUser?.profile}
                submitRequirement={submitRequirement}
                formData={formData?.tranches?.data || formData?.tranches}
                listCategory={listCategory}
                onCancelKols={handleCancelKols}
                isLoading={isLoading}
                btnSubmitRef={btnSubmitRef}
                handleSaveDraft={setSaveDraft}
                backToStep={backToStep}
                startDate={formData?.start_date ? new Date(formData?.start_date) : null}
                endDate={formData?.end_date ? new Date(formData?.end_date) : null}
              />
            </div>
          </>
        );
      case CREATE_STEPS.campaignReview:
        return (
          <CampaignPreview
            handlePublishCampaign={handlePublishCampaign}
            onEditCampaign={handleEditCampaign}
            handleUpdateCampaign={handleUpdateCampaign}
            isLoading={isLoading}
            user={currentUser}
            onRefreshWallet={onRefreshWallet}
            backToStep={backToStep}
            campaignDetail={campaignDetail}
            isSuccessPublish={isSuccessPublish}
            isFailedPublish={isFailedPublish}
            onCloseFailedPublish={() => setIsFailedPublish(false)}
          />
        );
      default:
        return <CreateCampaignDetail submitCampaign={submitCampaign} formData={formData} />;
    }
  };

  const handleOnDiscard = () => {
    if (draftCampaignId !== undefined) {
      deleteCampaign({
        variables: {
          id: draftCampaignId,
        },
      });
    }
    history.push(PRIVATE_ROUTE.campaigns);
  };

  const handleOnDiscardTeams = () => {
    if (draftCampaignId !== undefined) {
      deleteCampaign({
        variables: {
          id: draftCampaignId,
        },
      });
    }
    history.push(PRIVATE_ROUTE.teams);
  };

  const handleOnSaveDraft = () => {
    setSaveDraft(true);
    if (btnSubmitDetailRef.current && !isLoading) {
      btnSubmitDetailRef.current.click();
      setIsShowModel(false);
    }
    setTimeout(() => {
      if (btnSubmitRef.current && !isLoading) {
        btnSubmitRef.current.click();
        setIsShowModel(false);
      }
    }, 500);
  };

  const renderHeaderCenter = (
    <MenuItemStyled onClick={handleOnDiscard}>
      <Typography text="Campaigns" color="#5770C6" fontSize="16px / 19px" fontWeight="500" />
    </MenuItemStyled>
  );

  const renderHeaderTeams = (
    <MenuItemStyled onClick={handleOnDiscardTeams}>
      <Typography text="Teams" color="#5770C6" fontSize="16px / 19px" fontWeight="500" />
    </MenuItemStyled>
  );

  return (
    <>
      {isMobile ? (
        <>{renderAsideRight()}</>
      ) : (
        <MainLayout
          hasButtonNew
          headerCenter={renderHeaderCenter}
          headerTeam={renderHeaderTeams}
          asideLeft={
            <AsideLeftCampaign
              backToStep={handleBackToStep}
              step={step}
              avatar={formData?.image_url || campaignDetail?.image_url}
              campaignName={formData?.name || campaignDetail?.name}
            />
          }
          asideRight={renderAsideRight()}
        />
      )}

      <ModalCampaignCancel
        onDiscard={handleOnDiscard}
        onCancel={() => setIsShowModel(false)}
        onSaveDraft={handleOnSaveDraft}
        width="376px"
        isPassiveCancel={true}
        isOpen={isShowModel}
      />
    </>
  );
}

export default CreateCampaign;

const MenuItemStyled = styled.div<any>``;
