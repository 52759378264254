import React, { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import GridStyled from '../styled/GridStyled';
import RowStyled from '../styled/RowStyled';
import LabelStyled from '../commons/Label';
import Collapsible from 'react-collapsible';
import messages from '../../utils/messages';
import { format, parseISO } from 'date-fns';
import * as R from 'ramda';

import { ReactComponent as ArrowDownSVG } from '../../assets/svg/drop_down_bottom.svg';
import { ReactComponent as MoreSVG } from '../../assets/svg/more.svg';

// assets
import { FORMAT_DATE_DEFAULT } from '../../utils/constants';
import CampaignIconSocial from './CampaignIconSocial';
import { fortmatCurrencyTemplate } from '../../utils/formatCurrency';
import { mobileQueryString } from '../../utils/responsive';
import useConvertCurrency from '../../hooks/useConvertCurrency';
import useGuard from '../../hooks/useGuard';

const arrayFrom = R.defaultTo([]);
const RECRUITMENT_DEADLINE_TIME_FORMAT = 'do LLL, yy';
const CAMPAIGN_PERIOD_TIME_FORMAT = 'do LLL, yy';

const CampaignDetailView = ({ data, symbol }) => {
  const [indexGroup, setIndexGroup] = useState(0);
  const [totalKOLs, setTotalKOLs] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalPIK, setTotalPIK] = useState(0);
  const { exchangeRate } = useConvertCurrency();
  const { user } = useGuard();

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const { cash_usd_cents, pik, number_kols } = data?.tranches?.reduce(
        (total, curr) => {
          return {
            cash_usd_cents: total.cash_usd_cents + parseInt(curr.cash_usd_cents),
            pik: total.pik + parseInt(curr.product_value_usd_cents),
            number_kols: total.number_kols + parseInt(curr.number_kols),
          };
        },
        {
          cash_usd_cents: 0,
          pik: 0,
          number_kols: 0,
        },
      );
      setTotalKOLs(number_kols);
      setTotalPIK(pik);
      setTotalCash(cash_usd_cents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const listImage = useMemo(() => {
    if (!data || !Array.isArray(data?.images)) return [];
    return data?.images.map((tranche) => tranche.file_metadata).flat();
  }, [data]);

  return (
    <>
      <CampaignContainer pt="20px" maxWidth="100%">
        <GridStyled>
          <RowStyled>
            <GalleryImage>
              {arrayFrom(listImage).map((image) => {
                return <Image key={image.id} src={image?.url} />;
              })}
            </GalleryImage>
          </RowStyled>
        </GridStyled>
        <GridStyled>
          <RowCustomStyled mb="30px">
            <FlexBoxMobile>
              <ListCampaignInfo mr="72px">
                <LabelLightColor>{messages['campaigns.product']}</LabelLightColor>
                <LabelBoldColor>{data?.product_name}</LabelBoldColor>
              </ListCampaignInfo>
              <ListCampaignInfo>
                <LabelLightColor>{messages['campaigns.qytOfKols']}</LabelLightColor>
                <LabelBoldColor>{totalKOLs}</LabelBoldColor>
              </ListCampaignInfo>
            </FlexBoxMobile>
            <LineVertical isHigh />
            <FlexBoxMobile>
              <ListCampaignInfo mr="38.79px">
                <LabelLightColor>
                  <NoteBudgetIcon>
                    {messages['campaigns.totalRewards']}
                    <MoreSVG />
                  </NoteBudgetIcon>
                </LabelLightColor>
                <LabelBoldColor>
                  {fortmatCurrencyTemplate(
                    (data?.budget_usd_cents / 100) * exchangeRate,
                    user?.profile?.default_currency?.template,
                    symbol ?? user?.profile?.default_currency?.symbol,
                    user?.profile?.default_currency.fraction_size,
                  )}
                </LabelBoldColor>
              </ListCampaignInfo>
              <FlexBox>
                <ListCampaignInfo mr="32px">
                  <LabelLightColor>{messages['campaigns.cash']}</LabelLightColor>
                  <LabelBoldColor>
                    {fortmatCurrencyTemplate(
                      (totalCash / 100) * exchangeRate,
                      user?.profile?.default_currency?.template,
                      symbol ?? user?.profile?.default_currency?.symbol,
                      user?.profile?.default_currency?.fraction_size,
                    )}
                  </LabelBoldColor>
                </ListCampaignInfo>
                <ListCampaignInfo>
                  <LabelLightColor>{messages['campaigns.pik']}</LabelLightColor>
                  <LabelBoldColor>
                    {fortmatCurrencyTemplate(
                      (totalPIK / 100) * exchangeRate,
                      user?.profile?.default_currency?.template,
                      symbol ?? user?.profile?.default_currency?.symbol,
                      user?.profile?.default_currency?.fraction_size,
                    )}
                  </LabelBoldColor>
                </ListCampaignInfo>
              </FlexBox>
            </FlexBoxMobile>
          </RowCustomStyled>
        </GridStyled>
      </CampaignContainer>
      <LineStyled isOutside />
      <CampaignContainer>
        <GridStyled>
          <ProductRowStyled mb="13px">
            <LabelLightColor>{messages['campaigns.recruitmentDeadline']}</LabelLightColor>
            <LabelBoldColor block>
              {!!data.recruitment_end_date && format(new Date(data.recruitment_end_date), RECRUITMENT_DEADLINE_TIME_FORMAT)}
            </LabelBoldColor>
          </ProductRowStyled>
          <ProductRowStyled mb="13px">
            <LabelLightColor>{messages['campaigns.campaignPeriod']}</LabelLightColor>
            <LabelBoldColor block>
              {!!data?.start_date &&
                !!data?.end_date &&
                [data?.start_date, data?.end_date].map((t) => format(new Date(t), CAMPAIGN_PERIOD_TIME_FORMAT)).join(' - ')}
            </LabelBoldColor>
          </ProductRowStyled>
        </GridStyled>
        <LineStyled mb="13px" />
        <GridStyled>
          <ProductRowStyled mb="13px">
            <LabelLightColor>{messages['campaigns.campaignDetails']}</LabelLightColor>
            <CampaignDetailsStyled>{data?.description}</CampaignDetailsStyled>
          </ProductRowStyled>
        </GridStyled>
      </CampaignContainer>
      <LineStyled />
      <TitleStyled mb="3px">{messages['campaigns.kolsRequirements']}</TitleStyled>
      <KolsContainer>
        {arrayFrom(data?.tranches).map((element, idx) => (
          <CollapsibleGroup
            open={idx === indexGroup}
            trigger={
              <CollapsibleTitle className="Collapsible__title">
                <GroupTitle>{element?.name}</GroupTitle>
                <ArrowDownSVG className="Collapsible__arrow" />
              </CollapsibleTitle>
            }
            idx={idx}
            length={arrayFrom(element?.tranches).length}
            key={idx}
            handleTriggerClick={(accordionPosition) => {
              setIndexGroup(idx === indexGroup ? null : idx);
            }}
          >
            <KolsRequirementGroup
              data={element}
              idx={idx}
              currency={user?.profile?.default_currency}
              length={arrayFrom(element?.tranches).length}
              exchangeRate={exchangeRate}
              symbol={symbol}
            />
          </CollapsibleGroup>
        ))}
      </KolsContainer>
    </>
  );
};

const KolsRequirementGroup = (props: any) => {
  const { data = {}, exchangeRate, symbol, currency } = props;
  return (
    <KolsRequirementItem>
      <TitleStyled mb="17px">{messages['campaigns.kolAffinity']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListTag>
            {arrayFrom(data?.product_categories).map((tag, idx) => (
              <TagItem key={idx}>{tag?.product_category?.name}</TagItem>
            ))}
          </ListTag>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled isMarginMobile>
          <ListCampaignInfo mr="34px">
            <LabelLightColor>{messages['campaigns.minFollowers']}</LabelLightColor>
            <LabelBoldColor>{data?.min_follower_size}</LabelBoldColor>
          </ListCampaignInfo>
          <LineVertical />
          <ListCampaignInfo mr="5px">
            <LabelLightColor>{messages['campaigns.maxFollowers']}</LabelLightColor>
            <LabelBoldColor>{data?.max_follower_size}</LabelBoldColor>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <TitleNote>
            If 2 or more platforms are selected, the follower size will refer to the total followers aggregated from the selected
            platforms.
          </TitleNote>
        </RowCustomStyled>
      </GridStyled>
      <LineStyled />
      <TitleStyled mb="13px">{messages['campaigns.remunerationPerKol']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="30px">
          <ListCampaignInfo mr="76px">
            <LabelLightColor>{messages['campaigns.cash']}</LabelLightColor>
            <LabelBoldColor>
              {fortmatCurrencyTemplate(
                (data?.cash_usd_cents / 100) * exchangeRate,
                currency?.template,
                symbol ?? currency?.symbol,
                currency?.fraction_size,
              )}
            </LabelBoldColor>
          </ListCampaignInfo>
          <ListCampaignInfo mr="0px">
            <LabelLightColor>{messages['campaigns.pikValue']}</LabelLightColor>
            <LabelBoldColor>
              {fortmatCurrencyTemplate(
                (data?.product_value_usd_cents / 100) * exchangeRate,
                currency?.template,
                symbol ?? currency?.symbol,
                currency?.fraction_size,
              )}
            </LabelBoldColor>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <GridStyled>
        <RowCustomStyled>
          <ListCampaignInfo>
            <LabelLightColor>{messages['campaigns.pikDescription']}</LabelLightColor>
            <TextContent>{data?.product_description}</TextContent>
          </ListCampaignInfo>
        </RowCustomStyled>
      </GridStyled>
      <LineStyled />
      <GridStyled>
        <RowCustomStyled mb="16px" isColumn>
          <ListCampaignInfoKols mr="0px">
            <LabelLightColor>{messages['campaigns.noOfKoLs']}</LabelLightColor>
            <LabelMediumColor>{data?.number_kols}</LabelMediumColor>
          </ListCampaignInfoKols>
          <LineVertical2 />
          <ListCampaignInfoKols mr="0px">
            <LabelLightColor>{messages['campaigns.platforms']}</LabelLightColor>
            <PlatformStyled>
              <CampaignIconSocial platforms={data?.social_platforms} />
            </PlatformStyled>
          </ListCampaignInfoKols>
          <LineVertical2 />
          <ListCampaignInfoKols mr="0px">
            <LabelLightColor>{messages['campaigns.ageGroup']}</LabelLightColor>
            <AgeGroup>
              {arrayFrom(data?.age_groups).map((rage, idx) => (
                <AgeItem key={idx}>{rage?.age_category?.name}</AgeItem>
              ))}
            </AgeGroup>
          </ListCampaignInfoKols>
          <LineVertical2 />
          <ListCampaignInfoKols mr="0px">
            <LabelLightColor>{messages['campaigns.gender']}</LabelLightColor>
            {data?.genders.map((g, idx) => {
              return <LabelMediumColor key={idx}>{g?.gender?.description}</LabelMediumColor>;
            })}
          </ListCampaignInfoKols>
          <LineVertical2 />
          <ListCampaignInfoKols mr="0px">
            <LabelLightColor>{messages['campaigns.languages']}</LabelLightColor>
            {data?.mapping_tranche_languages.map((g, idx) => {
              return <LabelMediumColor key={idx}>{g?.language?.title}</LabelMediumColor>;
            })}
          </ListCampaignInfoKols>
        </RowCustomStyled>
      </GridStyled>
      <LineStyled />
      <TitleStyled mb="13px">{messages['campaigns.deliverables']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="18px">
          <DeliverableContainer>
            {arrayFrom(data?.deliverable_specs).map((deliverable, idx) => (
              <DeliverableItem key={idx}>
                <GridStyled>
                  <RowCustomStyled mb="24px" isColumn>
                    <ListCampaignInfoKols mr="0px">
                      <LabelLightColor>{messages['campaigns.noOfDeliverable']}</LabelLightColor>
                      <LabelBoldColor>{deliverable?.quantity}</LabelBoldColor>
                    </ListCampaignInfoKols>
                    <LineVertical2 />
                    <ListCampaignInfoKols mr="0px">
                      <LabelLightColor>{messages['campaigns.type']}</LabelLightColor>
                      <LabelBoldColor>{deliverable?.content_type?.name}</LabelBoldColor>
                    </ListCampaignInfoKols>
                    <LineVertical2 />
                    <ListCampaignInfoKols mr="0px">
                      <LabelLightColor>{messages['campaigns.dueDate']}</LabelLightColor>
                      <LabelBoldColor>
                        {!!deliverable?.due_date && format(parseISO(deliverable?.due_date), FORMAT_DATE_DEFAULT)}
                      </LabelBoldColor>
                    </ListCampaignInfoKols>
                  </RowCustomStyled>
                </GridStyled>
                <LabelLightColor>{messages['campaigns.deliverablesDescription']}</LabelLightColor>
                <span className="label">{deliverable?.requirement_description}</span>
              </DeliverableItem>
            ))}
          </DeliverableContainer>
        </RowCustomStyled>
      </GridStyled>
      <TitleStyled mb="10px">{messages['campaigns.otherRequirements']}</TitleStyled>
      <GridStyled>
        <RowCustomStyled mb="30px">
          <TextContent>{data?.requirements}</TextContent>
        </RowCustomStyled>
      </GridStyled>
    </KolsRequirementItem>
  );
};

export default CampaignDetailView;

const CampaignContainer = styled.div<any>`
  ${(props) =>
    !!props.pt &&
    css`
      padding-top: ${props.pt || 0};
    `};
  ${(props) =>
    !!props.maxWidth &&
    css`
      max-width: ${props.maxWidth || '700px'};
    `};

  ${mobileQueryString} {
    padding: 20px 0 0;
  }
`;

const KolsContainer = styled.div<any>`
  max-width: 100%;

  ${mobileQueryString} {
    margin: 20px 0;
  }
`;

const GalleryImage = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-right: -11.7px;
`;

const Image = styled.img`
  max-width: 100%;
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
  display: block;
  border-radius: 5px;
`;

const ListCampaignInfo = styled.div<any>`
  ${(props) =>
    !!props.mr &&
    css`
      margin-right: ${props.mr || 0};
    `};
`;

const LabelLightColor = styled<any>(LabelStyled)`
  color: #717171;
  font: normal normal 500 14px 'Inter';
  margin-bottom: 4px;
`;

const LabelBoldColor = styled<any>(LabelStyled)`
  color: #0b0b0b;
  font: normal normal bold 16px Inter;
  margin-bottom: 0px;
  ${(props: any) =>
    props.block &&
    css`
      display: flex;
    `}
`;

const LabelMediumColor = styled<any>(LabelStyled)`
  color: #3e3e3e;
  font: normal normal 500 16px/19px 'Helvetica Neue';
  margin-bottom: 0px;
  svg {
    width: 14px;
    height: 14px;
  }
`;

const PlatformStyled = styled<any>(LabelMediumColor)`
  svg {
    width: 14px;
    height: 14px;
  }
  & > svg + svg {
    margin-left: 14px;
  }
`;

const RowCustomStyled = styled<any>(RowStyled)`
  margin-bottom: ${(props) => props.mb || '18px'};
  width: ${(props) => props.width || '100%'};

  ${mobileQueryString} {
    & > div {
      ${(props) =>
        props.isMarginMobile &&
        css`
          margin-right: 0;
          &:not(:first-child) {
            margin-left: 0px;
          }
        `}
    }

    ${(props) =>
      props.isColumn &&
      css`
        flex-direction: column;

        & > div {
          margin-right: 0;
          display: flex;
          justify-content: space-between;
          padding: 0 0;
          column-gap: 20px;
        }
      `}
  }
`;

const ProductRowStyled = styled<any>(RowStyled)`
  flex-direction: column;
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: 13px;
    `}

  ${mobileQueryString} {
    margin-bottom: 18px;
  }
`;

const CampaignDetailsStyled = styled.p<any>`
  margin: 0;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #3e3e3e;
`;

const TitleStyled = styled.p<any>`
  color: #5770c6;
  font: normal normal 500 16px/19px 'Helvetica Neue';
  margin: 0;
  margin-bottom: ${(props) => props.mb || '18px'};

  ${mobileQueryString} {
    ${(props) =>
      props.isHasPadding &&
      css`
        padding: 0 20px;
      `}
  }
`;

const LineStyled = styled.div<any>`
  width: 100%;
  position: relative;
  height: 1px;
  display: block;
  margin-bottom: ${(props) => props.mb || '18px'} !important;
  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 0px;
    height: 1px;
    width: 100%;
    background: #eeeeee;
    left: 50%;
    transform: translateX(-50%);
  }

  ${mobileQueryString} {
    width: ${(props) => props.isOutside && 'calc(100% - 40px)'};
    margin: 0 auto;
  }
`;

const CollapsibleTitle = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 19.5px;
  cursor: pointer;
  font-family: 'Helvetica Neue';
  background-color: rgba(238, 238, 238, 0.25);
`;

const CollapsibleGroup = styled<any>(Collapsible)`
  & svg.Collapsible__arrow {
    transition: all 0.5s;
  }

  & .is-closed {
    span {
      color: #949494 !important;
      font: normal normal normal 14px/16px 'Helvetica Neue';
    }
    & svg.Collapsible__arrow {
      transform: rotate(-90deg);
    }
    & .Collapsible__title {
      background-color: transparent;
      ${(props) =>
        props.idx !== props.length - 1 &&
        css`
          border-bottom: 1px solid #eeeeee;
        `}
    }
  }
  & .is-open {
    color: #0b0b0b;
    font: normal normal 500 14px/17px 'Helvetica Neue';
    & svg.Collapsible__arrow {
      transform: rotate(360deg);
    }
    & .Collapsible__title {
      border-bottom: none;
    }
  }
`;

const KolsRequirementItem = styled.div<any>`
  padding-left: 20px;
  padding-right: 19.5px;
  background-color: rgba(238, 238, 238, 0.25);
`;

const GroupTitle = styled.span<any>`
  font: normal normal 500 14px/17px Helvetica Neue;
  color: #0b0b0b;
`;

const ListTag = styled.div<any>`
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
`;

const TagItem = styled.span<any>`
  display: block;
  font: normal normal normal 13px/15px Helvetica Neue;
  color: #3e3e3e;
  padding: 7px 8px;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  margin-right: 10px;
`;

const TitleNote = styled.p<any>`
  color: #949494;
  font: normal normal normal 12px/14px Helvetica Neue;
  margin: 0;
  padding: 0;
`;

const TextContent = styled.p<any>`
  margin: 0;
  font: normal normal normal 14px/16px Helvetica Neue;
  color: #3e3e3e;
`;

const AgeGroup = styled.div<any>`
  display: flex;
  margin-right: -20px;
  flex-wrap: wrap;

  ${mobileQueryString} {
    justify-content: flex-end;
  }
`;

const AgeItem = styled.div<any>`
  padding-right: 20px;
  color: #3e3e3e;
  font: normal normal 500 16px/19px Helvetica Neue;
`;

const DeliverableContainer = styled.div<any>`
  width: 100%;
`;

const DeliverableItem = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 24px;
  padding-left: 0px;
  &:not(:first-child) {
    margin-top: 11px;
  }
  & .label {
    font: normal normal normal 13px/15px Helvetica Neue;
    color: #3e3e3e;
  }
  & .date-title {
    font: normal normal 500 12px/15px Helvetica Neue;
    color: #5770c6;
    display: block;
    text-align: right;
  }
  & .date-time {
    font: normal normal bold 12px/15px Helvetica Neue;
    color: #0b0b0b;
    text-align: right;
  }

  ${mobileQueryString} {
    & .mr-t {
      margin-top: -7px;
    }
  }
`;

const NoteBudgetIcon = styled.div<any>`
  position: relative;
  svg {
    width: 11px;
    height: 11px;
    fill: none;
    stroke: #5770c6;
    margin-left: 6px;
  }
  &:hover::after {
    display: block;
  }
  &::after {
    content: 'Cash + PIK \\A e.g. if current currency is MYR: USD1000 * 4.23 = MYR4230 ';
    position: absolute;
    display: none;
    background-color: #eeeeee;
    color: #3e3e3e;
    font: normal normal normal 10px/12px Helvetica Neue;
    width: 268px;
    left: 84%;
    top: -100%;
    transform: translate(0, -11.79px);
    padding: 2px 4px 3px;
    border-radius: 4px;
    white-space: pre;
  }
`;
const LineVertical = styled.div<any>`
  width: 1px;
  height: 25px;
  margin-left: 16px;
  margin-right: 22px;
  background-color: #eeeeee;

  ${mobileQueryString} {
    height: ${(props) => props.isHigh && '114px'};
  }
`;

const LineVertical2 = styled.div<any>`
  width: 1px;
  height: 42px;
  margin-left: 32px;
  margin-right: 32px;
  background-color: #d9d9d9;

  ${mobileQueryString} {
    height: ${(props) => props.isHigh && '114px'};
  }
`;

const FlexBox = styled.div<any>`
  display: flex;
`;

const FlexBoxMobile = styled<any>(FlexBox)`
  ${mobileQueryString} {
    flex-direction: column;
    row-gap: 18px;
  }
`;

const ListCampaignInfoKols = styled.div<any>`
  ${(props) =>
    !!props.mr &&
    css`
      margin-right: ${props.mr || 0};
    `};

  ${mobileQueryString} {
    & > div,
    & > label {
      width: auto;
    }
  }
`;
