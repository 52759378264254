import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from './utils/constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ToastProvider } from 'react-toast-notifications';

import MerchantRoute from './components/commons/MerchantRoute';
import CustomAuth0Provider from './containers/CustomAuth0Provider';
import CustomApolloProvider from './containers/CustomApolloProvider';

import Authorization from './containers/authentication/Authorization';
import Auth0Callback from './containers/authentication/Auth0Callback';

import MerchantRegister from './pages/MerchantRegister';
import ClientRegister from './pages/ClientRegister';
import PendingRegister, { TokenExpired, UnAvailableAccount } from './pages/PendingRegister';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import Campaigns from './pages/campaigns/Campaigns';
import CampaignCreate from './pages/campaigns/CampaignCreate';
import CampaignDetail from './pages/campaigns/CampaignDetail';
import CampaignListOfKols from './pages/campaigns/CampaignListOfKols';
import CampaignChat from './pages/campaigns/CampaignChat';
import CampaignDeliverables from './pages/campaigns/CampaignDeliverable';
import UserProfile from './pages/UserProfile';
import CompanyProfile from './pages/CompanyProfile';
import MyWallet from './pages/MyWallet';
import CampaignReport from './pages/campaigns/CampaignReport';
import ContentMetrics from './pages/campaigns/ContentMetrics';
import PublicCampaignTerm from './components/campaigns/CreateCampaign/CampaignTerm/PublicCampaignTerm';
import Teams from './pages/teams/Teams';
import SelectMerchant from './pages/SelectMerchant';
import { ThemeProvider } from '@mui/material/styles';
import theme from './style/style';
import { ClientProfileProvider } from './containers/ClientProfileProvider';

function App() {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ToastProvider newestOnTop={true}>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CustomAuth0Provider>
              <CustomApolloProvider>
                <ClientProfileProvider>
                  <ThemeProvider theme={theme}>
                    <Switch>
                      <MerchantRoute private path={PRIVATE_ROUTE.merchantRegister} component={MerchantRegister} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.dashboard} component={Dashboard} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.pendingReview} component={PendingRegister} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.accountUnavailable} component={UnAvailableAccount} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.selectMerchant} component={SelectMerchant} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.clientRegister} component={ClientRegister} exact />

                      {/* Campaigns */}
                      <MerchantRoute private path={PRIVATE_ROUTE.campaigns} component={Campaigns} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.campaignsOfProfile} component={Campaigns} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.campaignsCreate} component={CampaignCreate} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.campaignsDetails} component={CampaignDetail} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.campaignsListOfKols} component={CampaignListOfKols} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.campaignsReport} component={CampaignReport} exact />
                      <MerchantRoute private path={PRIVATE_ROUTE.campaignsChat} component={CampaignChat} />
                      <MerchantRoute private path={PRIVATE_ROUTE.campaignsDeliverables} component={CampaignDeliverables} />
                      <MerchantRoute private path={PRIVATE_ROUTE.companyProfile} component={CompanyProfile} />
                      <MerchantRoute private path={PRIVATE_ROUTE.userProfile} component={UserProfile} />
                      <MerchantRoute private path={PRIVATE_ROUTE.wallet} component={MyWallet} />
                      <MerchantRoute private path={PRIVATE_ROUTE.contentMetrics} component={ContentMetrics} />
                      <MerchantRoute private path={PRIVATE_ROUTE.publishCampaignTerms} component={PublicCampaignTerm} />

                      {/* Teams */}
                      <MerchantRoute private path={PRIVATE_ROUTE.teams} component={Teams} exact />

                      {/* Campaigns */}

                      <MerchantRoute path={PUBLIC_ROUTE.login} component={Authorization} exact />
                      <MerchantRoute path={PUBLIC_ROUTE.notFound} component={NotFound} exact />
                      <MerchantRoute path={PUBLIC_ROUTE.auth0Callback} component={Auth0Callback} />
                      <MerchantRoute path={PUBLIC_ROUTE.tokenExpired} component={TokenExpired} exact />

                      <Redirect from="/" to={PRIVATE_ROUTE.campaigns} exact />
                      <Redirect from="*" to={PUBLIC_ROUTE.notFound} exact />
                    </Switch>
                  </ThemeProvider>
                </ClientProfileProvider>
              </CustomApolloProvider>
            </CustomAuth0Provider>
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </ToastProvider>
    </React.Fragment>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`

`;
