import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import useMobile from '../../../hooks/useMobile';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';
import { ReactComponent as FilerIcon } from '../../../assets/svg/filer.svg';
import { ReactComponent as FileDownloadSVG } from '../../../assets/svg/file_download_black_24dp.svg';
import Table from '../../commons/Table';
import { format } from 'date-fns';
import Button from '../../commons/Button';
import CampaignIconSocial from '../CampaignIconSocial';
import { toCommas } from '../../../utils/heplers';
import PropTypes from 'prop-types';
import { useDebounce } from 'react-use';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import * as R from 'ramda';

import useCommonApi from '../../../hooks/useCommon';
import { DEFAULT_AVATAR } from '../../../utils/constants';
import { mobileQueryString } from '../../../utils/responsive';
import CampaignListOfKolMobileApply from './CampaignMobileApply/CampaignListOfKolMobileApply';
import CampaignListOfKolMobileAccept from './CampaignMobileAccept/CampaignListOfKolMobileAccept';
import { STATUS } from './const/index';
import ApprovePayRequestPopUp from '../../popUp/payRequest/approve/ApprovePayRequestPopUp';
import useGuard from '../../../hooks/useGuard';
import RejectCampaignPopUp from '../../popUp/payRequest/reject/RejectCampaignPopUp';
import RejectPayRequestPopUp from '../../popUp/payRequest/reject/RejectPayRequestPopUp';
import useConvertCurrency from '../../../hooks/useConvertCurrency';
import { fortmatCurrencyTemplate } from '../../../utils/formatCurrency';
import { FEATURE_KEY, useFeatureFlag } from '../../../hooks/useFeatureFlag';
import Stripe from '../../stripe/Stripe';
import StripeForm from '../../popUp/stripe/StripeForm';
import InsufficientCreditPopup from '../../popUp/topUp/insufficientCredit/InsufficientCreditPopup';
import ConfirmMoneyPopUp from '../../popUp/confirm/ConfirmMoneyPopUp';
import GET_CONFIG from '../../../queries/common/config';
import { useQuery } from '@apollo/client';

const TIME_FORMAT = 'do LLL, yy';
const DEADLINE_FORMAT_TIME = 'dd MMM yyyy';
export const CAMPAIGN_TAB = {
  applied: 'KOL_APPLIED',
  accepted: 'CLIENT_APPROVED',
};
const CampaignListOfKol = React.forwardRef<any, any>(
  (
    {
      data = [],
      onGotoChat,
      onGotoDeliverables,
      onTabChange,
      onAccept,
      onAcceptItems,
      onApprovePay,
      onPayWorkContracts,
      onReject,
      onRejectPay,
      onDownloadPdf,
      onSearch,
    },
    ref,
  ) => {
    const [tab, setTab] = useState(CAMPAIGN_TAB.applied);
    const [search, setSearch] = useState<any>(null);
    const history = useHistory();
    const isMobile = useMobile();
    const query = qs.parse(history.location.search) as any;
    const { listCategory, onRefreshListCategory } = useCommonApi();
    const [approveWorkcontract, setApproveWorkcontract] = useState<any>(null);
    const { user, onRefreshWallet, onRefreshUser, onRefreshProfile } = useGuard();
    const [rejectWorkcontract, setRejectWorkcontract] = useState<any>(null);
    const [rejectRequestPayContract, setRejectRequestPayContract] = useState<any>(null);
    const { exchangeRate } = useConvertCurrency() as any;
    const { isFeatureFlagEnable, loading } = useFeatureFlag();
    const [payingWorkContracts, setPayingWorkContracts] = useState<any>([]);
    const [isShowStripeForm, setIsShowStripeForm] = useState(false);
    const [amount, setAmount] = useState(0);
    const [requireAmount, setRequireAmount] = useState(0);
    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const [isShowInsufficient, setIsShowInsufficient] = useState(false);
    const { data: configResponse } = useQuery(GET_CONFIG);

    const payTotalCents = data.reduce((sum, workContract) => {
      const additionalPay =
        workContract.status?.code === STATUS.CLIENT_APPROVED_PAY_REQUEST.value ? workContract.additional_pay_usd_cents || 0 : 0;
      return sum + additionalPay;
    }, 0);

    const walletValue = useMemo(() => {
      return Number(((user?.profile?.wallet || 0) / 100).toFixed(2));
    }, [user?.profile?.wallet]);

    useEffect(() => {
      if (!user.profile || !user.profile.wallet_id) {
        onRefreshUser();
        onRefreshProfile();
      }
    }, [onRefreshProfile, onRefreshUser, user.profile]);

    useEffect(() => {
      onRefreshWallet();
    }, [onRefreshWallet]);

    useEffect(() => {
      if (payingWorkContracts === null || payingWorkContracts === undefined || payingWorkContracts.length === 0) {
        setIsShowInsufficient(false);
        setIsShowConfirm(false);
        return;
      }
      const totalCents = payingWorkContracts.reduce((sum, workContract) => {
        const additionalPay = workContract.additional_pay_usd_cents || 0;
        return sum + additionalPay;
      }, 0);
      const fee = configResponse?.config?.find((item) => item.key === 'platform_fee_merchant_publish_campaign')?.value || 0;
      const cents = (totalCents * (1.0 + Number(fee))) / 100;
      const balance = Number(((user?.profile?.wallet || 0) / 100).toFixed(2));
      if (balance >= cents) {
        setIsShowConfirm(true);
        setIsShowInsufficient(false);
      } else {
        setIsShowConfirm(false);
        setIsShowInsufficient(true);
      }
      setRequireAmount(cents);
    }, [payingWorkContracts, user?.profile?.wallet, configResponse]);

    const requireAmountDisplay = useMemo(() => {
      return Number((requireAmount * exchangeRate).toFixed(user?.profile?.default_currency?.fraction_size ?? 0));
    }, [requireAmount, user?.profile?.default_currency?.fraction_size, exchangeRate]);

    const walletDisplayValue = useMemo(() => {
      return Number((walletValue * exchangeRate).toFixed(user?.profile?.default_currency?.fraction_size ?? 0));
    }, [walletValue, user?.profile?.default_currency?.fraction_size, exchangeRate]);

    const removeDuplicatePlatformMap = (platForm = []) => {
      const map = new Map();
      platForm.forEach((f: any) => {
        map.set(f.social_platform_category.code, f);
      });

      const newPlatform = Array.from(map, ([_, value]) => ({ ...value }));
      return newPlatform;
    };

    const handleGotoChat = (item) => {
      onGotoChat && onGotoChat(item);
    };

    const handleGotoDeliverables = (item) => {
      onGotoDeliverables && onGotoDeliverables(item);
    };

    const handleAccept = (item) => {
      onAccept && onAccept(item);
    };

    const handleApprovePay = (item) => {
      onApprovePay && onApprovePay(item);
      setApproveWorkcontract(null);
    };

    const handlePayNow = (item) => {
      setPayingWorkContracts([item]);
    };

    const handlePayTotal = () => {
      const workContracts = data.filter((item) => {
        if (item.status?.code === STATUS.CLIENT_APPROVED_PAY_REQUEST.value) {
          return true;
        }
        if (
          (item.status?.code === STATUS.SUBMITTED.value || item.status?.code === STATUS.KOL_APPLIED.value) &&
          (item.additional_pay_usd_cents === undefined ||
            item.additional_pay_usd_cents === null ||
            item.additional_pay_usd_cents <= 0)
        ) {
          return true;
        }
        return false;
      });
      const totalCents = workContracts.reduce((sum, workContract) => {
        const additionalPay = workContract.additional_pay_usd_cents || 0;
        return sum + additionalPay;
      }, 0);
      if (totalCents <= 0) {
        onAcceptItems && onAcceptItems(workContracts);
        return;
      }
      setPayingWorkContracts(workContracts);
    };

    const handlePayWorkContracts = () => {
      onPayWorkContracts && onPayWorkContracts(payingWorkContracts);
      setIsShowStripeForm(false);
      setIsShowConfirm(false);
      setPayingWorkContracts([]);
      setApproveWorkcontract(null);
    };

    const handleOpenStripeForm = useCallback(() => {
      if (walletValue >= requireAmount) {
        return;
      }
      if (isFeatureFlagEnable(FEATURE_KEY.stripe)) {
        setIsShowStripeForm(true);
      } else {
        alert('use ops-tool to add credit to Merchant wallet');
      }
    }, [requireAmount, walletValue]);

    const handleCloseStripeForm = useCallback((status) => {
      if (status) {
        setIsShowStripeForm(false);
      }
    }, []);

    const handleReject = (item) => {
      onReject && onReject(item);
      setRejectWorkcontract(null);
      setRejectRequestPayContract(null);
    };

    const handleRejectPay = (item) => {
      onRejectPay && onRejectPay(item);
      setRejectWorkcontract(null);
      setRejectRequestPayContract(null);
    };

    const handleShowReject = (item) => {
      setRejectWorkcontract(item);
    };

    const handleShowRejectPayRequest = (item) => {
      setRejectRequestPayContract(item);
    };

    const handleApproveItem = (item) => {
      setApproveWorkcontract(item);
    };

    const handleDownloadPdf = () => {
      onDownloadPdf && onDownloadPdf();
    };

    const handleSearch = (e) => {
      const text = e.target.value;
      setSearch(text);
    };

    useDebounce(
      () => {
        if (!search && typeof search === 'object') return;
        onSearch && onSearch(search);
      },
      400,
      [search],
    );

    useEffect(() => {
      if (Object.keys(listCategory).length === 0) {
        onRefreshListCategory();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columnApplied = useMemo(() => {
      const columns = [
        {
          Header: 'Kols',
          isSort: true,
          accessor: (d) => d,
          minWidth: '220px',
          Cell: ({ cell }) => {
            const item = cell?.value;
            const kol_profile = item?.kol_profile;
            const platform = removeDuplicatePlatformMap(kol_profile?.social_platforms);
            const avatarFileMetadata = kol_profile?.kol_file_metadata?.find((item) => item.type === `avatar`);
            const avatarURL = avatarFileMetadata?.file_metadata.url || DEFAULT_AVATAR;
            return (
              <KolCard
                isAccepted={false}
                name={`${kol_profile?.first_name || ''} ${kol_profile?.last_name || ''}`}
                gender={kol_profile?.gender.toLowerCase()}
                platform={platform}
                avatar={avatarURL}
                onGoToChat={() => handleGotoChat(item)}
                onGoToDeliverables={() => handleGotoDeliverables(item)}
              />
            );
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Data Applied',
          accessor: 'kol_profile.created_at',
          Cell: ({ cell }) => {
            const value = cell.value;
            return <div>{format(new Date(value), TIME_FORMAT)}</div>;
          },
          isSort: true,
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Followers Size',
          accessor: 'kol_profile.follower_size',
          Cell: ({ cell }) => {
            const value = cell.value || 0;
            return toCommas(value);
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Engagement Rate',
          accessor: 'engagement_rate',
          Cell: ({ cell }) => {
            const value = cell.value || 0;
            return value + '%';
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Group Category',
          accessor: 'tranche',
          Cell: ({ cell }) => {
            const value = cell.value || 0;
            return value?.name || '';
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
      ];
      const additionalColumns = [
        {
          Header: 'Additional Pay Requested',
          accessor: (d) => d,
          Cell: ({ cell }) => {
            const item = cell.value;
            if (item !== undefined && item.additional_pay_usd_cents !== undefined && item.additional_pay_usd_cents > 0) {
              const additionalText = fortmatCurrencyTemplate(
                (item.additional_pay_usd_cents / 100) * exchangeRate,
                user?.profile?.default_currency?.template,
                user?.profile?.default_currency?.symbol,
                user?.profile?.default_currency?.fraction_size ?? 2,
              );
              if (item.status.code === STATUS.CLIENT_APPROVED_PAY_REQUEST.value) {
                return <AdditionalMoneyApprovedStyled>{additionalText}</AdditionalMoneyApprovedStyled>;
              } else {
                return <AdditionalMoneyStyled>{additionalText}</AdditionalMoneyStyled>;
              }
            }
            return '';
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
      ];
      const actionColumns = [
        {
          Header: ' ',
          accessor: (d) => d,
          Cell: ({ cell }) => {
            const item = cell.value;
            if (item.status.code === STATUS.clientReject.value || item.status.code === STATUS.clientRejectPay.value) {
              return (
                <ActionCellStyled>
                  <ButtonActionStyled variant="secondary" label="REJECTED" disabled />
                </ActionCellStyled>
              );
            }
            if (item.additional_pay_usd_cents !== undefined && item.additional_pay_usd_cents > 0) {
              if (item.status.code === STATUS.CLIENT_APPROVED_PAY_REQUEST.value) {
                return (
                  <ActionCellStyled>
                    <ButtonActionStyled variant="secondary" label="REJECT" onClick={() => handleShowRejectPayRequest(item)} />
                    <ButtonActionStyled variant="primary" label="ACCEPT" onClick={() => handlePayNow(item)} />
                  </ActionCellStyled>
                );
              }
              return (
                <ActionCellStyled>
                  <ButtonActionStyled variant="secondary" label="REJECT" onClick={() => handleShowRejectPayRequest(item)} />
                  <ButtonActionStyled variant="primary" label="APPROVE" onClick={() => handleApproveItem(item)} />
                </ActionCellStyled>
              );
            }
            return (
              <ActionCellStyled>
                <ButtonActionStyled variant="secondary" label="REJECT" onClick={() => handleShowReject(item)} />
                <ButtonActionStyled variant="primary" label="ACCEPT" onClick={() => handleAccept(item)} />
              </ActionCellStyled>
            );
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
      ];
      if (isFeatureFlagEnable(FEATURE_KEY.additionalRemuneration)) {
        return [...columns, ...additionalColumns, ...actionColumns];
      } else {
        return [...columns, ...actionColumns];
      }
    }, [loading]);

    const columnAccepted = useMemo(() => {
      return [
        {
          Header: 'Kols',
          isSort: true,
          accessor: (d) => d,
          minWidth: '220px',
          Cell: ({ cell }) => {
            const item = cell?.value;
            const kol_profile = item?.kol_profile;
            const platform = removeDuplicatePlatformMap(kol_profile?.social_platforms);
            const avatarFileMetadata = kol_profile?.kol_file_metadata?.find((item) => item.type === `avatar`);
            const avatarURL = avatarFileMetadata?.file_metadata.url || DEFAULT_AVATAR;
            return (
              <KolCard
                isAccepted={true}
                name={`${kol_profile?.first_name || ''} ${kol_profile?.last_name || ''}`}
                gender={kol_profile?.gender.toLowerCase()}
                platform={platform}
                avatar={avatarURL}
                onGoToChat={() => handleGotoChat(item)}
                onGoToDeliverables={() => handleGotoDeliverables(item)}
              />
            );
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Data Applied',
          accessor: 'kol_profile.created_at',
          Cell: ({ cell }) => {
            const value = cell.value;
            return <div>{format(new Date(value), TIME_FORMAT)}</div>;
          },
          isSort: true,
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Followers Size',
          accessor: 'kol_profile.follower_size',
          Cell: ({ cell }) => {
            const value = cell.value || 0;
            return toCommas(value);
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Engagement',
          accessor: 'engagement_rate',
          Cell: ({ cell }) => {
            const value = cell.value || 0;
            return value + '%';
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Group Category',
          accessor: 'tranche.name',
          isSort: true,
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Next Deadline',
          accessor: 'nextDeadline',
          Cell: ({ cell }) => {
            const item = cell.row.original;
            const kol_profile = item?.kol_profile;
            return <div>{format(new Date(kol_profile?.created_at), DEADLINE_FORMAT_TIME)}</div>;
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
        {
          Header: 'Status',
          accessor: (d) => d,
          Cell: ({ cell }) => {
            const value = cell.value;
            const listWorkItem = value?.work_items || [];
            const totalItem = listWorkItem.length;
            const countWorkItemApproved = listWorkItem.filter((w) => w?.status.code === STATUS.APPROVED.value).length;
            const isCompleted = totalItem === countWorkItemApproved && totalItem !== 0;
            const status = isCompleted ? STATUS.completed : STATUS.onGoing;
            return (
              <StatusStyled color={status?.color}>
                <div className="status">{status?.label}</div>
                <div className="step">{`${countWorkItemApproved}/${totalItem}`}</div>
              </StatusStyled>
            );
          },
          style: {
            verticalAlign: 'middle',
          },
          headerClassName: 'table-header-kols-padding',
          className: 'table-row-kols-padding',
        },
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const column = useMemo(() => {
      return tab === CAMPAIGN_TAB.applied ? columnApplied : columnAccepted;
    }, [tab, columnAccepted, columnApplied]);

    const handleChangeTab = (tabIndex) => () => {
      setTab(tabIndex);
    };

    useEffect(() => {
      onTabChange && onTabChange(tab);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    useEffect(() => {
      if (R.is(String, query.tab)) {
        setTab(query.tab);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (query.tab !== tab) {
        const queryParams = qs.parse(history.location.search) as any;
        const newQueries = { ...queryParams, tab };
        history.push({ search: qs.stringify(newQueries) });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.tab, tab]);

    return (
      <WrapperStyled>
        <LOKHeaderStyled>
          <LOKHeaderTabStyled>
            <div>
              <LOKHeaderButtonStyled active={tab === CAMPAIGN_TAB.applied} onClick={handleChangeTab(CAMPAIGN_TAB.applied)}>
                Applied
              </LOKHeaderButtonStyled>
              <LOKHeaderButtonStyled active={tab === CAMPAIGN_TAB.accepted} onClick={handleChangeTab(CAMPAIGN_TAB.accepted)}>
                Accepted
              </LOKHeaderButtonStyled>
            </div>
            <ButtonMobileWrapper>
              <ButtonMobile>
                <SearchIcon />
              </ButtonMobile>
              <ButtonMobile>
                <FilerIcon />
              </ButtonMobile>
              <ButtonMobile onClick={handleDownloadPdf}>
                <FileDownloadSVG />
                <div>PDF</div>
              </ButtonMobile>
            </ButtonMobileWrapper>
          </LOKHeaderTabStyled>
          <div className="tools">
            <LOKHeaderSearchStyled>
              <input placeholder="Start typing.." type="text" onChange={handleSearch} />
              <SearchIcon className="icon" width="16px" height="16px" stroke="#5770c6" />
            </LOKHeaderSearchStyled>
            {tab === CAMPAIGN_TAB.accepted && (
              <DownloadButtonStyled onClick={handleDownloadPdf}>
                <FileDownloadSVG fill="#5770c6" />
                <span>Download PDF</span>
              </DownloadButtonStyled>
            )}
          </div>
        </LOKHeaderStyled>

        {approveWorkcontract !== null && approveWorkcontract !== undefined && !isShowConfirm && !isShowInsufficient && (
          <ApprovePayRequestPopUp
            handlePublish={() => handlePayNow(approveWorkcontract)}
            handleApprove={() => handleApprovePay(approveWorkcontract)}
            currency={user?.profile?.default_currency?.code}
            onClose={() => setApproveWorkcontract(null)}
            workContract={approveWorkcontract}
          />
        )}

        {rejectWorkcontract !== null && rejectWorkcontract !== undefined && (
          <RejectCampaignPopUp
            onClose={() => setRejectWorkcontract(null)}
            onConfirm={() => handleReject(rejectWorkcontract)}
            workContract={rejectWorkcontract}
          />
        )}

        {rejectRequestPayContract !== null && rejectRequestPayContract !== undefined && (
          <RejectPayRequestPopUp
            onClose={() => setRejectRequestPayContract(null)}
            onReject={() => handleReject(rejectRequestPayContract)}
            onRejectPay={() => handleRejectPay(rejectRequestPayContract)}
            workContract={rejectRequestPayContract}
          />
        )}

        {payingWorkContracts !== null && payingWorkContracts.length > 0 && isShowConfirm && (
          <ConfirmMoneyPopUp
            confirmTitle={'CONFIRM'}
            handlePublish={handlePayWorkContracts}
            isLoadingPublish={false}
            requireAmount={requireAmountDisplay}
            requireAmountUSD={requireAmount}
            onClose={() => {
              setIsShowConfirm(false);
            }}
            currency={user?.profile?.default_currency?.code ?? 'USD'}
          />
        )}

        {payingWorkContracts !== null && payingWorkContracts.length > 0 && isShowInsufficient && (
          <InsufficientCreditPopup
            walletDisplay={walletDisplayValue}
            walletUSD={walletValue}
            display_currency={user?.profile?.default_currency?.code ?? 'USD'}
            requireAmount={requireAmountDisplay}
            requireAmountUSD={requireAmount}
            onClose={() => {
              setIsShowInsufficient(false);
            }}
            showAdditionalMessage={true}
            onSubmit={handleOpenStripeForm}
            amount={amount}
            handleAmount={setAmount}
          />
        )}

        {isShowStripeForm && (
          <Stripe>
            <StripeForm
              onClose={() => setIsShowStripeForm(false)}
              value={amount}
              user={user}
              handlePublish={handleCloseStripeForm}
              onRefreshWallet={onRefreshWallet}
            />
          </Stripe>
        )}

        {isMobile ? (
          <>
            {tab === CAMPAIGN_TAB.applied && (
              <CampaignListOfKolMobileApply ref={ref} data={data} handleAccept={handleAccept} handleReject={handleReject} />
            )}
            {tab === CAMPAIGN_TAB.accepted && (
              <CampaignListOfKolMobileAccept
                ref={ref}
                data={data}
                onGoToChat={handleGotoChat}
                onGoToDeliverables={handleGotoDeliverables}
              />
            )}
          </>
        ) : (
          <Table ref={ref} columns={column} data={data} isHiddenPagination={true} />
        )}

        {isFeatureFlagEnable(FEATURE_KEY.additionalRemuneration) && tab === CAMPAIGN_TAB.applied && (
          <PayTotalHeaderStyled>
            <PayTotalTitleStyled>Total Agreed Additional Pay</PayTotalTitleStyled>
            <PayTotalValueStyled>
              {fortmatCurrencyTemplate(
                (payTotalCents / 100) * exchangeRate,
                user?.profile?.default_currency?.template,
                user?.profile?.default_currency?.symbol,
                user?.profile?.default_currency?.fraction_size ?? 2,
              )}
            </PayTotalValueStyled>
            <PayTotalButtonStyled onClick={handlePayTotal}>
              <span>Accept All</span>
            </PayTotalButtonStyled>
          </PayTotalHeaderStyled>
        )}
      </WrapperStyled>
    );
  },
);

CampaignListOfKol.propTypes = {
  data: PropTypes.array,
  onGotoChat: PropTypes.func,
  onGotoDeliverables: PropTypes.func,
  onTabChange: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onRejectPay: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  onSearch: PropTypes.func,
};

export default CampaignListOfKol;

const WrapperStyled = styled.div<any>`
  & .tools {
    display: inline-flex;
    & button {
      margin-left: 20px;
    }
  }

  ${mobileQueryString} {
    padding: 0 20px;

    & .tools {
      display: none;
    }
  }
`;

const LOKHeaderStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const LOKHeaderTabStyled = styled.div<any>`
  ${mobileQueryString} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #eeeeee;
  }
`;

const LOKHeaderButtonStyled = styled.button<any>`
  font: normal normal normal 16px/18px Helvetica Neue;
  color: #949494;
  display: inline-flex;
  padding: 10px 20px 50px 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: #5770c6;
      border-bottom: 2px solid #5770c6;
      font-weight: 500;
    `}

  ${mobileQueryString} {
    padding: 0 0 20px 0;
    margin-right: 25px;
  }
`;

const LOKHeaderSearchStyled = styled.div<any>`
  height: 46px;
  width: 292px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  --padding-right: 22px;
  padding: 10px var(--padding-right);
  box-sizing: border-box;
  border-radius: 5px;
  & input {
    width: calc(100% - var(--padding-right));
    border: none;
    font: normal normal normal 14px/16px Helvetica Neue;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #949494;
    }
  }
  & .icon {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ButtonActionStyled = styled<any>(Button)`
  width: 87px;
  height: 38px;
  ${(props) =>
    props.variant === 'secondary' &&
    css`
      & {
        background-color: transparent;
        color: #949494;
      }
      &:hover {
        background-color: #949494;
        color: #fff;
      }
    `}
  ${(props) =>
    props.variant === 'primary' &&
    css`
      & {
        background: transparent;
        color: #5770c6;
      }
      &:hover {
        background: transparent linear-gradient(263deg, #51adf0 0%, #666bfa 49%, #b64dc8 100%) 0% 0% no-repeat padding-box;
        color: #fff;
      }
    `}
    ${(props) =>
    !!props.disabled &&
    css`
      &:hover {
        background-color: transparent;
        color: #949494;
      }
    `}
`;

const ActionCellStyled = styled.div<any>`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const KolCard = (props: any) => {
  const {
    name = '',
    gender = '',
    avatar = '',
    platform = [],
    isAccepted = false,
    onGoToChat = () => {},
    onGoToDeliverables = () => {},
  } = props;
  return (
    <KolCardStyled>
      <div className="left">
        <img src={avatar} alt="" />
      </div>
      <div className="right">
        <div className="name">{name}</div>
        {isAccepted && (
          <div className="action-navigation">
            <div onClick={onGoToDeliverables}>Deliverables</div>
            <div className="chat-label" onClick={onGoToChat}>
              Chat
            </div>
          </div>
        )}
        <div className="bottom">
          <div className="gender">{gender}</div>
          <CampaignIconSocial platforms={platform} />
        </div>
      </div>
    </KolCardStyled>
  );
};

const KolCardStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  & .left {
    img {
      border-radius: 5px;
      width: 43px;
      height: 43px;
    }
  }
  & .right {
    margin-left: 28px;
  }
  & .name {
    color: #0b0b0b;
    text-align: left;
    font: normal normal bold 16px/19px Helvetica Neue;
  }
  & .action-navigation {
    display: flex;
    margin-bottom: 7px;
    color: #5770c6;
    font: normal normal bold 12px/15px Helvetica Neue;
    & .chat-label {
      margin-left: 13px;
    }
    & > div {
      cursor: pointer;
    }
  }
  & .bottom {
    display: flex;
    & .gender {
      font: normal normal normal 13px/15px Helvetica Neue;
      color: #949494;
      text-transform: capitalize;
      margin-right: 18px;
    }
  }
`;

const StatusStyled = styled.div<any>`
  & .status {
    font: normal normal normal 14px/16px Helvetica Neue;
    margin-bottom: 6px;
  }
  & .step {
    color: #949494;
    font: normal normal normal 14px/16px Helvetica Neue;
  }
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const DownloadButtonStyled = styled.button<any>`
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  width: 160px;
  height: 46px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #5770c6;
  cursor: pointer;
  & span {
    margin-left: 12px;
  }
`;

const ButtonMobileWrapper = styled.div<any>`
  display: none;

  ${mobileQueryString} {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    column-gap: 10px;
  }
`;

const ButtonMobile = styled.div<any>`
  height: 30px;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  font-size: 12px;
  color: #5770c6;

  & svg {
    width: 13px;
    height: 13px;
    fill: #5770c6;
  }
`;

const AdditionalMoneyStyled = styled.button<any>`
  font: normal normal regular 14px/18px Inter;
  color: #e43a15;
  background-color: transparent;
  border: 0px;
  text-align: right;
  justify-content: right;
  align-items: right;
  margin-leaf: 12px;
`;

const AdditionalMoneyApprovedStyled = styled.button<any>`
  font: normal normal regular 14px/18px Inter;
  color: #3fb608;
  background-color: transparent;
  border: 0px;
  text-align: right;
  justify-content: right;
  align-items: right;
  margin-leaf: 12px;
`;

const PayTotalHeaderStyled = styled.div<any>`
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
  align-items: center;
  spacing: 12px;
`;

const PayTotalTitleStyled = styled.div<any>`
  color: #000000;
  background-color: transparent;
  border: 0px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

const PayTotalValueStyled = styled.div<any>`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%;
  margin-left: 12px;
  margin-right: 48px;
`;

const PayTotalButtonStyled = styled.button<any>`
  background-color: rgba(219, 16, 142, 0.1);
  border-radius: 5px;
  display: flex;
  width: 156px;
  height: 44px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  color: #5770c6;
  cursor: pointer;
  border: none;
  & span {
    margin-left: 0px;
    color: var(--KOLs-Blue, #5770c6);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%;
  }
`;
